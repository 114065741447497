import React, {
  FC,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react"
import './createOrEditBuildingModal.scoped.scss'
import { ModalBase } from "components/common/modals/modalBase/modalBase"
import { LightButton } from "components/common/buttons/lightButton/lightButton"
import { PrimaryButton } from "components/common/buttons/primaryButton/primaryButton"
import {
  ImportCalculationPointsModalResult,
} from "components/calculationTask/importCalculationPointsModal/importCalculationPointsModal"
import { ModalProps } from "services/modal/modalService"
import { TextInput } from "components/common/formFields/textInput/textInput"
import { MultiToggle } from "components/common/buttons/multiToggle/multiToggle"
import { CheckboxInput } from "components/common/formFields/CheckboxInput/CheckboxInput"
import { useFormik } from "formik"
import {
  BuildingFormDataModel,
  buildingValidationSchema,
  CreateOrEditBuildingFormErrors,
} from "components/calculationTask/createOrEditBuildingModal/buildingFormDataModel"
import {
  VertexInputFieldSetHandle,
  VertexInputFieldSetProps,
} from "components/calculationTask/createOrEditBuildingModal/vertexInputTypes"
import { CoordinateSystem } from "services/sicalcApi/sharedEntities/coordinateSystem"
import {
  CoordinateSystemSelectionField,
} from "components/common/formFields/coordinateSystemSelectionField/coordinateSystemSelectionField"
import { toast } from "react-hot-toast"
import { BuildingPreview } from "components/calculationTask/createOrEditBuildingModal/buildingPreview/buildingPreview"
import {
  GeographicVertexInputFieldSet,
} from "components/calculationTask/createOrEditBuildingModal/geographicVertexInputFieldSet/geographicVertexInputFieldSet"
import {
  UtmVertexInputFieldSet,
} from "components/calculationTask/createOrEditBuildingModal/utmVertexInputFieldSet/utmVertexInputFieldSet"
import { ReferenceAltitude } from "services/sicalcApi/sharedEntities/referenceAltitude"
import { GeometryType } from "services/sicalcApi/sharedEntities/geometryType"
import { Vertex } from "services/sicalcApi/sharedEntities/vertex"
import { useSicalcApiService } from "services/sicalcApi/sicalcApiService"
import {
  NtmVertexInputFieldSet
} from "components/calculationTask/createOrEditBuildingModal/ntmVertexInputFieldSet/ntmVertexInputFieldSet"

export interface Building { // TODO: Consolidate with API
  name: string
  referenceAltitude: ReferenceAltitude
  geometryType: GeometryType
  createFacadePointsAt4m: boolean
  createFacadePointsAt1_5m: boolean
  facadePointsMaxSpacing: number
  facadePointsDistanceFromFacade: number
  vertices: Vertex[]
}

export interface CreateOrEditBuildingModalProps extends ModalProps {
  heading: string
  initialValue?: Building
  coordinateSystem?: CoordinateSystem
}

export interface CreateOrEditBuildingModalResult {
  success: boolean
  newBuilding: Building
}


export const CreateOrEditBuildingModal: FC<CreateOrEditBuildingModalProps> = (props) => {
  const vertexInputFieldSetRef = useRef<VertexInputFieldSetHandle>(null)
  // TODO: Get initial coordinate system from props
  const [ coordinateSystem, setCoordinateSystem ] = useState<CoordinateSystem>(CoordinateSystem.Utm)
  const [ facadePoints, setFacadePoints ] = useState<Vertex[]>([])
  const { buildingApi } = useSicalcApiService()


  const initialValues: BuildingFormDataModel = {
    name: props.initialValue?.name ?? '',
    geometryType: props.initialValue?.geometryType ?? "polygon",
    createFacadePointsAt4m: props.initialValue?.createFacadePointsAt4m ?? true,
    createFacadePointsAt1_5m: props.initialValue?.createFacadePointsAt1_5m ?? true,
    facadePointsMaxSpacing: props.initialValue?.facadePointsMaxSpacing ?? 5,
    facadePointsDistanceFromFacade: props.initialValue?.facadePointsDistanceFromFacade ?? 2,
    referenceAltitude: props.initialValue?.referenceAltitude ?? "terrain",
    vertices: props.initialValue?.vertices ?? [],
  }

  const form = useFormik({
    initialValues,
    validationSchema: buildingValidationSchema,
    onSubmit: () => Promise.resolve(true),
  })

  const dismiss = () => {
    return props.modalContext.close({ success: false } as ImportCalculationPointsModalResult)
  }

  const submit = async () => {
    // Fetch the values from the nested vertex input field set
    const vertexInputFieldValues = await vertexInputFieldSetRef.current!.getValues()
    if (vertexInputFieldValues.validationSucceeded) {
      await form.setFieldValue('vertices', vertexInputFieldValues.vertices)
    }

    const formIsValid: true | undefined = await form.submitForm()

    if (!formIsValid ||  !vertexInputFieldValues.validationSucceeded) {
      console.debug("Validation failure\nValues:", form.values, "Errors:", form.errors)
      toast.error("Bygningen kunne ikke lagres fordi det er feil i skjemaet.")
      return
    }

    const result: CreateOrEditBuildingModalResult = {
      success: true,
      newBuilding: buildingValidationSchema.cast(form.values) as Building,
    }

    props.modalContext.close(result)
  }

  useEffect(() => {
    const updateFacadePoints = async () => {
      const formIsValid = await buildingValidationSchema.isValid(form.values)
      const shouldGenerateFacadePoints = form.values.geometryType === "polygon"
          && formIsValid
          && (
            form.values.createFacadePointsAt4m
            || form.values.createFacadePointsAt1_5m
          )
      if (shouldGenerateFacadePoints) {
        const values = buildingValidationSchema.cast(form.values) as Building
        const response = await buildingApi.generateFacadePoints({
          geometryType: values.geometryType,
          referenceAltitude: values.referenceAltitude,
          buildingVertices: values.vertices,
          maxSpacing: values.facadePointsMaxSpacing,
          distanceFromFacade: values.facadePointsDistanceFromFacade,
        })
        setFacadePoints(response.facadePoints)
      } else {
        setFacadePoints([])
      }
    }
    updateFacadePoints()
  },
  [
    buildingApi,
    form.values,
    form.values.createFacadePointsAt1_5m,
    form.values.createFacadePointsAt4m,
    form.values.vertices,
    form.values.geometryType,
    form.values.facadePointsMaxSpacing,
    form.values.facadePointsDistanceFromFacade,
  ])

  const onVerticesChanged = useCallback(async (validVertices: Vertex[]) => {
    // This function needs useCallback to avoid a render loop in children where it's passed as a prop
    await form.setFieldValue('vertices', validVertices)
  }, [form])

  const buildVertexInputFieldSet = () => {
    const vertexInputFieldSetProps: VertexInputFieldSetProps = {
      onChange: onVerticesChanged,
      initialValue: props.initialValue?.vertices ?? [],
    }
    if (coordinateSystem === CoordinateSystem.Geographic) {
      return (
        <GeographicVertexInputFieldSet
          ref={vertexInputFieldSetRef}
          {...vertexInputFieldSetProps}
        />
      )
    }
    if (coordinateSystem === CoordinateSystem.Utm) {
      return (
        <UtmVertexInputFieldSet
          ref={vertexInputFieldSetRef}
          {...vertexInputFieldSetProps}
        />
      )
    }
    if (coordinateSystem === CoordinateSystem.Ntm) {
      return (
        <NtmVertexInputFieldSet
          ref={vertexInputFieldSetRef}
          {...vertexInputFieldSetProps}
        />
      )
    }
  }

  return (
    <ModalBase
      onDismiss={dismiss}
      dismissOnClickOutside={false}
      heading={props.heading}
      actions={
        <>
          <LightButton
            onClick={dismiss}
          >
            Avbryt
          </LightButton>
          <PrimaryButton
            onClick={submit}
          >
            Lagre
          </PrimaryButton>
        </>
      }
    >
      <div className="create-or-edit-building-modal column gap4">
        <label className="column">
          <span className="form-label">Navn</span>
          <TextInput
            name={`name`}
            value={form.values.name}
            isInvalid={!!form.touched.name && !!form.errors.name}
            errors={form.errors.name}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
          />
        </label>
        <label className="column">
          <span className="form-label">Type objekt</span>
          <MultiToggle
            value={form.values.geometryType}
            options={[
              {
                label: "Bygning",
                value: "polygon",
              },
              {
                label: "Skjerm",
                value: "polyline",
              },
            ]}
            onChange={newValue => form.setFieldValue("geometryType", newValue)}
          />
        </label>
        <div>
          <legend className="form-label">Beregningspunkt på fasade</legend>
          <div className="column gap1">
            <CheckboxInput
              label="1,5 m over terrenget"
              value={form.values.createFacadePointsAt1_5m}
              onChange={newValue => form.setFieldValue('createFacadePointsAt1_5m', newValue)}
            />
            <CheckboxInput
              label="4 m over terrenget"
              value={form.values.createFacadePointsAt4m}
              onChange={newValue => form.setFieldValue('createFacadePointsAt4m', newValue)}
            />
          </div>
        </div>
        {(form.values.createFacadePointsAt4m || form.values.createFacadePointsAt1_5m) && (
          <>
            <label className="column">
              <span className="form-label">Maksimum horisontal avstand (m)</span>
              <TextInput
                name={`facadePointsMaxSpacing`}
                value={form.values.facadePointsMaxSpacing}
                isInvalid={!!form.touched.facadePointsMaxSpacing && !!form.errors.facadePointsMaxSpacing}
                errors={form.errors.facadePointsMaxSpacing}
                onChange={form.handleChange}
                onBlur={form.handleBlur}
              />
            </label>
            <label className="column">
              <span className="form-label">Avstand fra fasaden (m)</span>
              <TextInput
                name={`facadePointsDistanceFromFacade`}
                value={form.values.facadePointsDistanceFromFacade}
                isInvalid={!!form.touched.facadePointsDistanceFromFacade && !!form.errors.facadePointsDistanceFromFacade}
                errors={form.errors.facadePointsDistanceFromFacade}
                onChange={form.handleChange}
                onBlur={form.handleBlur}
              />
            </label>
          </>
        )}

        <label className="column">
          <span className="form-label">Høydeangivelse</span>
          <MultiToggle
            value={form.values.referenceAltitude}
            options={[
              {
                label: "Relativt til terreng",
                value: "terrain",
              },
              {
                label: "Kotehøyde",
                value: "seaLevel",
              },
            ]}
            onChange={newValue => form.setFieldValue("referenceAltitude", newValue)}
          />
        </label>

        <div className="row">
          <CoordinateSystemSelectionField
            label={"Koordinatsystem"}
            value={coordinateSystem}
            isInvalid={false}
            onChange={(e) => setCoordinateSystem(e.target.value as CoordinateSystem)}
            disabled={false}
          />
        </div>
        <div className="vertices">
          {buildVertexInputFieldSet()}
          {!!form.touched.vertices
            && !!(form.errors as CreateOrEditBuildingFormErrors).allVertices
            && (
              <div className="validation-error mt4 row align-center">
                <i className="mdi mdi-alert-circle-outline mr1"></i>
                <p>
                  {(form.errors as CreateOrEditBuildingFormErrors).allVertices}
                </p>
              </div>
            )}
        </div>
        <BuildingPreview
          geometryType={form.values.geometryType}
          vertices={form.values.vertices as Vertex[]}
          facadePoints={facadePoints}
        />
      </div>
    </ModalBase>
  )
}
